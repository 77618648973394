import {
    MappedDefinition,
    MappedDefinitionCartConditionType,
    MappedDefinitionOffer,
    MappedDefinitionOfferProductType,
    MappedDefinitionOfferType,
} from 'components/campaigns/CampaignForm/CampaignDiscountCodeSelection/DiscountDefinitionParser';
import DiscountDefinitionParseError from 'errors/DiscountDefinitionParseError';
import {
    ARTICLE_TYPE_ALL,
    ARTICLE_TYPE_ALUMINIUM,
    ARTICLE_TYPE_CALENDAR,
    ARTICLE_TYPE_CANVAS,
    ARTICLE_TYPE_CARD,
    ARTICLE_TYPE_CUSHION,
    ARTICLE_TYPE_FOREX,
    ARTICLE_TYPE_JIGSAW,
    ARTICLE_TYPE_LOVEMUG,
    ARTICLE_TYPE_MAGICMUG,
    ARTICLE_TYPE_MAGNET,
    ARTICLE_TYPE_MUG,
    ARTICLE_TYPE_PHOTOBLOCK,
    ARTICLE_TYPE_PHOTOBOOK,
    ARTICLE_TYPE_PADDED_PHOTOBOOK,
    ARTICLE_TYPE_CUTOUT_PHOTOBOOK,
    ARTICLE_TYPE_MOMENTS_PHOTOBOOK,
    ARTICLE_TYPE_PLEXIGLAS,
    ARTICLE_TYPE_POSTER,
    ARTICLE_TYPE_PRINTS,
    ARTICLE_TYPE_STUDENTPLAKAT,
} from 'models/articleType';
import { DiscountType, DISCOUNT_TYPE_PERCENTAGE } from 'models/discount';
import { isOfferSame, isSubsetOffer, isSupersetOffer, Offer } from 'models/offer';
import { Vendor as VendorModel, filterVendorSupportedArticleTypes } from 'models/vendor';
import { v4 as uuidv4 } from 'uuid';
import { MappedDefinitionCartCondition } from './DiscountDefinitionParser';

function adjustValues(adjust: Offer, check: Offer) {
    //if the value we are adjusting has a lower value than applicable discounts then we update it
    if (adjust.highest_offer < check.highest_offer) {
        adjust.highest_offer = check.highest_offer;
    }
    //if the applicable discount does not have a cart restriction then we can use its values
    if (!check.cartRestriction) {
        if (adjust.cartRestriction) {
            adjust.offer_value = check.offer_value;
            adjust.offer_percentage = check.offer_percentage;
            adjust.cartRestriction = false;
        } else {
            if (adjust.offer_value < check.offer_value) {
                adjust.offer_value = check.offer_value;
            }

            if (adjust.offer_percentage < check.offer_percentage) {
                adjust.offer_percentage = check.offer_percentage;
            }
        }
    }
}

export const deduplicateOffers = (existingOffers: Offer[]): Offer[] => {
    const temporaryOffers = new Array<Offer>();
    // we want to de duplicate offers based on article type
    // we want to keep both the highest value without a cart restriction & the highest offer value
    // e.g. 40% off Mug + 30% off Mug = 1 offer => 40% off ARTICLE_TYPE_MUG + highest = 40%
    // must handle cart restrictions
    // e.g. 40% off Mug when buy 2 + 30% off Mug = 1 offer => 30% off Mug + highest offer = 40%
    // should handle cross over
    // e.g. 40% off Mug when buy 2 + 30% off all = 2 offers => 30% off all + highest 30%  AND 30% off mug + highest 40% ?
    // will handle some mis configuration and overlaps
    // e.g. 20% off Mug when buy 2 + 30% off all = 2 offers => 30% off all + highest 30%  AND 30% off mug + highest 30% ?

    // will not handle some issues
    // e.g. 20% off Mug, Photobook, 30% off Photobook, Cards = 2 offers 20% off Mugs, Photobooks & 30% off Cards, Photobooks (the discount for photobooks should be 30% here but the overlap is hard)

    //we want to replace any cart restricted offer asap but keep its amount in the "highest value"
    existingOffers.map(x => {
        let lookup = temporaryOffers.find(y => isOfferSame(x, y));
        if (!lookup) {
            // this is the first offer of its type, there is no other like it yet
            // if it has a cart restriction, set base discount to 0
            const cloneOffer = x;
            cloneOffer.offer_percentage = x.cartRestriction ? 0 : x.offer_percentage;
            temporaryOffers.push(cloneOffer);
            lookup = cloneOffer;
        } else {
            //merge identical
            adjustValues(lookup, x);
        }

        //update this offer with super sets
        const superSets = temporaryOffers.filter(y => isSupersetOffer(x, y));
        superSets.map(y => adjustValues(lookup!, y));

        //update any subsets with this offer
        const subSets = temporaryOffers.filter(y => isSubsetOffer(x, y));
        subSets.map(y => adjustValues(y, lookup!));
    });
    return temporaryOffers;
};

export const prepareOffers = (
    parsedDefinition: MappedDefinition,
    campaignId: number,
    campaignManagerVendors: VendorModel[],
    vendorId: number,
): Array<Offer> => {
    const temporaryOffers = new Array<Offer>();

    parsedDefinition.vendorOffers.map(mappedDefinitionVendor => {
        mappedDefinitionVendor.vendors
            .filter(v => convertMappedVendor(v, campaignManagerVendors).id === vendorId)
            .map(vendor => {
                if (!mappedDefinitionVendor.offers.length) {
                    throw new Error(`Unable to parse offers for vendor ${vendor}, please create the offers manually.`);
                }
                mappedDefinitionVendor.offers.map(offer => {
                    const tempOffer = convertMappedDefinitionOfferToApiOffer(
                        offer,
                        parsedDefinition.discountCode,
                        campaignId,
                        vendor,
                        campaignManagerVendors,
                        mappedDefinitionVendor.cartCondition,
                    );
                    if (tempOffer) {
                        temporaryOffers.push(tempOffer);
                    }
                });
            });
    });

    return deduplicateOffers(temporaryOffers);
};

/**
 * Converts a discount definition mapped offer to a campaign manager offer
 *
 * @param mappedOffer MappedDefinitionOffer
 * @param promoCode string
 * @param campaignId number
 * @param vendor string
 * @param campaingManagerVendors VendorModel[]
 * @returns Offer
 */
export const convertMappedDefinitionOfferToApiOffer = (
    mappedOffer: MappedDefinitionOffer,
    promoCode: string,
    campaignId: number,
    vendor: string,
    campaingManagerVendors: VendorModel[],
    mappedCartCondition: MappedDefinitionCartCondition | null,
): Offer | null => {
    if (mappedOffer.product.includes(MappedDefinitionOfferProductType.Unknown)) {
        throw new DiscountDefinitionParseError(`Unable to parse Article Type ${mappedOffer.definition}`);
    }

    if (mappedOffer.type === MappedDefinitionOfferType.Unknown) {
        throw new DiscountDefinitionParseError(`Unable to parse Offer Type ${mappedOffer.definition}`);
    }

    //CMS-16289 if the restriction is a minimum quantity of 1 product that is not specific to a product then we can ignore it
    const quantityCartRestriction =
        mappedCartCondition?.type === MappedDefinitionCartConditionType.Quantity &&
        (!mappedCartCondition?.product.includes(MappedDefinitionOfferProductType.All) ||
            mappedCartCondition.amount > 1);
    // if the restriction is a minimum value of 1 (EUR, NK etc) then we can ignore it
    const valueCartRestriction =
        mappedCartCondition?.type === MappedDefinitionCartConditionType.Value && mappedCartCondition.amount > 1;
    const cartRestriction = quantityCartRestriction || valueCartRestriction;

    const offerArticleTypes = convertMappedArticleTypeWithExtras(mappedOffer.product);
    const vendorSupportedArticleTypes = filterVendorSupportedArticleTypes(offerArticleTypes, vendor.toUpperCase());

    if (!vendorSupportedArticleTypes.length) return null;

    const offer: Offer = {
        temporaryId: uuidv4(),
        vendor_id: convertMappedVendor(vendor, campaingManagerVendors).id,
        campaign_id: campaignId,
        article_types: vendorSupportedArticleTypes,
        pap_ids: mappedOffer.pap_ids,
        extras: mappedOffer.extras,
        offer_label: '', //todo mapp offer label
        promo_code: promoCode,
        activation_date: undefined,
        type: mappedOffer.type === MappedDefinitionOfferType.Percent ? DISCOUNT_TYPE_PERCENTAGE : ('' as DiscountType),
        end_date: undefined,
        offer_percentage: mappedOffer.type === MappedDefinitionOfferType.Percent ? mappedOffer.amount : 0,
        offer_value: mappedOffer.type !== MappedDefinitionOfferType.Percent ? mappedOffer.amount : 0,
        highest_offer: mappedOffer.amount,
        cartRestriction: cartRestriction,
    };

    return offer;
};

/**
 * Converts a discount definition vendor to a campaign manager vendor
 *
 * @param vendor string
 * @param vendors VendorModel[]
 * @returns VendorModel
 */
export const convertMappedVendor = (vendor: string, vendors: VendorModel[]): VendorModel => {
    const mappedVendor = vendors.find(
        vendorModel => vendorModel.country.toLowerCase() === vendor || vendorModel.name === vendor,
    );
    if (!mappedVendor) {
        throw new Error(`Unable to map ${vendor}`);
    }
    return mappedVendor;
};

/**
 * Converts a discount definition article type to a campaign manager article type
 *
 * @param articleType string
 * @returns string[]
 */
export const convertMappedArticleTypeWithExtras = (articleType: string[]): string[] => {
    const keywordMap = {
        [MappedDefinitionOfferProductType.PhotoBooks]: [ARTICLE_TYPE_PHOTOBOOK],
        [MappedDefinitionOfferProductType.PaddedPhotoBook]: [ARTICLE_TYPE_PADDED_PHOTOBOOK],
        [MappedDefinitionOfferProductType.CutoutPhotoBook]: [ARTICLE_TYPE_CUTOUT_PHOTOBOOK],
        [MappedDefinitionOfferProductType.MomentsPhotoBook]: [ARTICLE_TYPE_MOMENTS_PHOTOBOOK],
        [MappedDefinitionOfferProductType.Canvas]: [ARTICLE_TYPE_CANVAS],
        [MappedDefinitionOfferProductType.Forex]: [ARTICLE_TYPE_FOREX],
        [MappedDefinitionOfferProductType.Aluminium]: [ARTICLE_TYPE_ALUMINIUM],
        [MappedDefinitionOfferProductType.Perspex]: [ARTICLE_TYPE_PLEXIGLAS],
        [MappedDefinitionOfferProductType.Prints]: [ARTICLE_TYPE_PRINTS],
        [MappedDefinitionOfferProductType.Poster]: [ARTICLE_TYPE_POSTER],
        [MappedDefinitionOfferProductType.LoveMug]: [ARTICLE_TYPE_LOVEMUG],
        [MappedDefinitionOfferProductType.MagicMug]: [ARTICLE_TYPE_MAGICMUG],
        [MappedDefinitionOfferProductType.Mugs]: [ARTICLE_TYPE_MUG],
        [MappedDefinitionOfferProductType.Cards]: [ARTICLE_TYPE_CARD],
        [MappedDefinitionOfferProductType.Calendars]: [ARTICLE_TYPE_CALENDAR],
        [MappedDefinitionOfferProductType.Magnets]: [ARTICLE_TYPE_MAGNET],
        [MappedDefinitionOfferProductType.Jigsaw]: [ARTICLE_TYPE_JIGSAW],
        [MappedDefinitionOfferProductType.Cushions]: [ARTICLE_TYPE_CUSHION],
        [MappedDefinitionOfferProductType.Photoblock]: [ARTICLE_TYPE_PHOTOBLOCK],
        [MappedDefinitionOfferProductType.StudentPlakat]: [ARTICLE_TYPE_STUDENTPLAKAT],
        [MappedDefinitionOfferProductType.All]: ARTICLE_TYPE_ALL,
    };

    if (articleType.length === 1) {
        return keywordMap[articleType[0]];
    }

    return articleType.flatMap(a => keywordMap[a]);
};
