import { Attribution } from 'apis/attributionApi/attributions';
import { ErrorDetails, RequestState } from 'common/genericTypes';
import DiscountCodeError from 'errors/DiscountCodeError';

export type DiscountCodesPayload = {
    codes?: DiscountCodeType[];
    errors: DiscountCodeError[];
    count?: number;
};

export type DiscountGenrationProgress = { percent: number; message: string };

export type DiscountsForm = {
    formStep: number;
    discount: Partial<DiscountDefinition> & RequestState & ErrorDetails;
    discountCodes: Partial<DiscountCodesPayload> & RequestState;
    discountCodesGeneration: DiscountGenrationProgress;
    completedSteps: Array<number>;
    errors: Record<string, any>;
    requestState: string;
    attribution: Partial<Attribution> & RequestState & ErrorDetails;
    codeGenerationMode: DiscountGenerateCodes;
    amountToGenerate: '';
};

export type DiscountCodeType = {
    code: string;
    discountUUID: string | undefined;
    timestamp: string | undefined;
};

export type DiscountCodeGenerate = {
    discountUUID: string;
    amountOfCodes: string;
};

export type DiscountCodeGenerateSequence = {
    discountUUID: string;
    amountOfCodes: number;
    amountOfCodesTotal: number;
    runningTotal: number;
};

export const DiscountCodeGenerateOptions = {
    maxCodes: 7,
};

export enum DiscountStatus {
    draft = 'Draft',
    published = 'Published',
}

export enum DiscountUsageType {
    Multi = 'Multi',
    OncePerCustomer = 'OncePerCustomer',
    SingleUse = 'SingleUse',
}

export enum DiscountGenerateCodes {
    manually = 'Manually',
    bulk = 'Bulk',
}

export enum DiscountLayer {
    None = 'None',
    ListPrice = 'ListPrice',
    Automatic = 'Automatic',
    XSell = 'XSell',
    Coupon = 'Coupon',
    AutomaticShipping = 'AutomaticShipping',
    InternalCoupon = 'InternalCoupon',
}

export interface DiscountDefinition {
    id?: string;
    definition: string;
    validTo: Date;
    validFrom: Date;
    description: string;
    usageType: DiscountUsageType;
    layer: DiscountLayer;
    status: DiscountStatus;
    tags: string[];
    createdDate: string;
    lastChangedDate: string;
    amountOfCodes: string;
    codeGenerationMode: DiscountGenerateCodes;
}
