import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteDiscountCode,
    getDiscountByCode,
    getDiscountCodeCountByDiscountUuid,
    getDiscountCodesByDiscountUuid,
    getOldDiscountByCode,
    postDiscountCode,
    postGenerateDiscountCode,
} from 'apis/promotionsApi';
import { setShowLoader } from 'application/campaignForm/campaignCreate/visualFeedbackStore';
import DiscountCodeError from 'errors/DiscountCodeError';
import {
    DiscountCodeGenerate,
    DiscountCodeGenerateSequence,
    DiscountCodeGenerateOptions,
    DiscountCodesPayload,
    DiscountCodeType,
    DiscountDefinition,
    DiscountStatus,
} from 'models/discounts/discount';
import { completeDiscountFormSubmission } from './discountsSlice';

export const validateDiscountCode = createAsyncThunk('discountCode/validate', async (discountCode: string) => {
    let codesErrors: DiscountCodeError | null = null;
    let validatedCode: DiscountCodeType | null = null;

    try {
        const discountDefinition = await getDiscountByCode(discountCode);
        const oldDiscount = await getOldDiscountByCode(discountCode);
        const now = new Date();
        const validTo = new Date(discountDefinition?.validTo ?? 0);
        const newValidDiscountExists = discountDefinition?.status === DiscountStatus.published && validTo > now;
        if (newValidDiscountExists || oldDiscount) {
            codesErrors = new DiscountCodeError('Discount Code already exists', {
                conflict: true,
                discountDefinitionId: discountDefinition?.id as string,
                discountCode: discountCode,
                isOldDiscount: !!oldDiscount,
            });
        } else {
            validatedCode = { code: discountCode, discountUUID: discountDefinition?.id, timestamp: undefined };
        }
    } catch (error) {
        const codesErrors = new DiscountCodeError(error as string, {
            conflict: false,
            discountDefinitionId: '',
            discountCode: discountCode,
            isOldDiscount: false,
        });
    }

    return { validatedCode, errors: codesErrors };
});

export const removeDiscountCode = createAsyncThunk('discountCode/delete', async (discountCode: string, thunkApi) => {
    const discountCodes: Partial<DiscountCodesPayload> = (thunkApi.getState() as any).discountForm.discountCodes;
    const discount: Partial<DiscountDefinition> = (thunkApi.getState() as any).discountForm.discount;
    if (discount.id) {
        const existingDiscount = await getDiscountByCode(discountCode);
        if (existingDiscount && existingDiscount.id === discount.id) {
            await deleteDiscountCode(discountCode, discount.id);
        }
    }
    const codes = discountCodes && discountCodes.codes ? discountCodes.codes.filter(c => c.code !== discountCode) : [];
    return { codes: codes, errors: discountCodes.errors };
});

export const createDiscountCodesInBulk = async (discountCodes: DiscountCodeType[], thunkAPI) => {
    const codesErrors: DiscountCodeError[] = [];
    const codesCreated: DiscountCodeType[] = [];
    if (discountCodes.length) {
        for (const discountCode of discountCodes) {
            await postDiscountCode(discountCode.code, discountCode.discountUUID || '');
            codesCreated.push({
                code: discountCode.code,
                discountUUID: discountCode.discountUUID,
                timestamp: discountCode.timestamp,
            });
        }
    }

    if (!codesErrors.length) {
        await thunkAPI.dispatch(completeDiscountFormSubmission());
    }
    return { codes: codesCreated, errors: codesErrors };
};

export const createDiscountCodes = createAsyncThunk('discountCodes/create', createDiscountCodesInBulk);

const API_CODE_CREATING_LIMIT = 2000;
export const generateDiscountCodes = createAsyncThunk(
    'discountCodes/generate',
    async (discountData: DiscountCodeGenerate, thunkAPI) => {
        const state: any = thunkAPI.getState();
        const currentNumberOfCodes = Number(
            state.discountForm?.discountCodes?.count ?? state.discountForm?.discountCodes?.codes?.length ?? 0,
        );
        thunkAPI.dispatch(setShowLoader(true));
        thunkAPI.dispatch({
            type: 'generateCodes/progress',
            percent: 0,
            message: `Codes Generated : 0 / ${discountData.amountOfCodes}, Generating`,
        });
        const numberOfCodesToCreate = Number(discountData.amountOfCodes);
        thunkAPI.dispatch(
            generateDiscountCodesSequence({
                discountUUID: discountData.discountUUID,
                amountOfCodes: numberOfCodesToCreate, //we ask for all
                amountOfCodesTotal: numberOfCodesToCreate, //we expect all
                runningTotal: currentNumberOfCodes,
            }),
        );

        return { codes: new Array(currentNumberOfCodes) };
    },
);

export const generateDiscountCodesSequence = createAsyncThunk(
    'discountCodesGeneration/generateSequence',
    async (discountData: DiscountCodeGenerateSequence, thunkAPI) => {
        const amount = Number(discountData.amountOfCodes);
        const { runningTotal, amountOfCodesTotal } = discountData;

        if (amount > 0) {
            //we limit amount of codes to be created by max
            const amountToCreate = Math.min(API_CODE_CREATING_LIMIT, amount);
            thunkAPI.dispatch({
                type: 'generateCodes/progress',
                percent: Math.round(((amountOfCodesTotal - amount) / amountOfCodesTotal) * 100),
                message: `Generated : ${
                    amountOfCodesTotal - amount
                } / ${amountOfCodesTotal}, Generating ${amountToCreate}`,
            });
            //we request the batch of codes be created
            await postGenerateDiscountCode(discountData.discountUUID, amountToCreate.toString());
            //calculate remaining and queue up next batch
            const amountLeft = amount - amountToCreate;
            const newTotal = runningTotal + amountToCreate; //we update running total to included recreated
            thunkAPI.dispatch(
                generateDiscountCodesSequence({
                    discountUUID: discountData.discountUUID,
                    amountOfCodes: amountLeft, //we request the total remaining
                    amountOfCodesTotal: amountOfCodesTotal, //the total requested remains unchanged
                    runningTotal: newTotal,
                }),
            );

            return {
                codes: new Array(newTotal),
                count: newTotal,
            };
        } else {
            thunkAPI.dispatch(setShowLoader(false));
            thunkAPI.dispatch({
                type: 'generateCodes/progress',
                percent: 100,
                message: '',
            });
            return { codes: new Array(runningTotal + amount) };
        }
    },
);

export const retrieveDiscountCodesByDiscountUuid = createAsyncThunk(
    'discountCodes/get',
    async (action: { discountUUID: string; downloadMode: number }) => {
        const { discountUUID, downloadMode } = action;
        switch (downloadMode) {
            case 2: //today
                return {
                    codes: await getDiscountCodesByDiscountUuid(
                        discountUUID,
                        new Date(new Date().toJSON().slice(0, 10)),
                    ),
                };
            case 1: //all with date
                return { codes: await getDiscountCodesByDiscountUuid(discountUUID) };
            default: //all
                return { codes: await getDiscountCodesByDiscountUuid(discountUUID) };
        }
    },
);

export const countDiscountCodes = createAsyncThunk('discountCodes/count', async (discountUUID: string) => {
    const count = await getDiscountCodeCountByDiscountUuid(discountUUID);
    if (count < DiscountCodeGenerateOptions.maxCodes) {
        const discounts = await getDiscountCodesByDiscountUuid(discountUUID);
        return { count: count, codes: discounts };
    }
    return { count: count };
});

export const retrieveDiscountByDiscountCode = createAsyncThunk('discountCodes/get', async (discountCode: string) => {
    const discounts = await getDiscountByCode(discountCode);
    return discounts;
});
