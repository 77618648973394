import { routes } from 'routes';

export type NavItem = {
    route: string;
    label: string;
    automation: string;
};

export const navItems: ReadonlyArray<NavItem> = [
    {
        route: routes.campaigns,
        label: 'Campaign Manager',
        automation: 'campaigns',
    },
    {
        route: routes.vouchers,
        label: 'Vouchers',
        automation: 'vouchers',
    },
    {
        route: routes.discounts,
        label: 'Discounts',
        automation: 'discounts',
    },
    {
        route: routes.seo,
        label: 'SEO',
        automation: 'seo',
    },
    {
        route: routes.b2b,
        label: 'B2B',
        automation: 'b2b',
    },
    {
        route: routes.lod,
        label: 'LOD',
        automation: 'lod',
    },
];
