import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { mobileToDesktopNavBreakpointPx } from './breakpoint';
import { NavItem, navItems } from './navigationItems';

const useStyles = makeStyles()((theme: Theme) => ({
    navLink: {
        color: theme.palette.common.white,
        textDecoration: 'none',
        display: 'flex',
        height: '100%',
        '&:hover': {
            background: CustomColors.navHover,
        },
        padding: '0 10px 0',
    },
    navLinkSelected: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.light,
    },
    button: {
        textTransform: 'capitalize',
    },
    mobileHidden: {
        [theme.breakpoints.down(mobileToDesktopNavBreakpointPx)]: {
            display: 'none',
        },
    },
}));

export default memo(DesktopNav);

function DesktopNav(): React.ReactElement {
    const { classes } = useStyles();
    return (
        <Box className={classes.mobileHidden} display="flex" flexWrap="nowrap" alignContent="center">
            {navItems.map(navItem => {
                return <DesktopNavItem key={navItem.route} {...navItem} />;
            })}
        </Box>
    );
}

type DesktopNavItemProps = NavItem;

function DesktopNavItem({ route, label, automation }: DesktopNavItemProps): React.ReactElement {
    const { classes } = useStyles();

    return (
        <NavLink to={route} activeClassName={classes.navLinkSelected} className={classes.navLink}>
            <Button color="inherit" data-cy={`${automation}-desktop`}>
                <Typography variant="body1" className={classes.button}>
                    {label}
                </Typography>
            </Button>
        </NavLink>
    );
}
