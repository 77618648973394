import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem, Theme, Typography } from '@mui/material';
import React, { memo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { mobileToDesktopNavBreakpointPx } from './breakpoint';
import { NavItem, navItems } from './navigationItems';

const useStyles = makeStyles()((theme: Theme) => ({
    desktopHidden: {
        [theme.breakpoints.up(mobileToDesktopNavBreakpointPx)]: {
            display: 'none',
        },
    },
}));

export default memo(MobileNav);

function MobileNav(): React.ReactElement {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const history = useHistory();
    const { classes } = useStyles();

    const toggleNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (!anchorElNav) {
            setAnchorElNav(event.currentTarget);
        } else {
            handleCloseNavMenu();
        }
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const navigate = (route: string) => {
        history.push(route);
        handleCloseNavMenu();
    };

    return (
        <>
            <IconButton
                data-cy="nav-menu-button-mobile"
                size="large"
                onClick={toggleNavMenu}
                className={classes.desktopHidden}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                data-cy="nav-menu-links-mobile"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                className={classes.desktopHidden}
            >
                {navItems.map(navItem => {
                    return (
                        <MobileNavItem
                            key={navItem.route}
                            route={navItem.route}
                            label={navItem.label}
                            automation={navItem.automation}
                            navigate={navigate}
                        />
                    );
                })}
            </Menu>
        </>
    );
}

type MobileNavItemProps = NavItem & { navigate: (route: string) => void };

function MobileNavItem({ route, label, navigate, automation }: MobileNavItemProps): React.ReactElement {
    const match = useRouteMatch(route);
    const onNavItemClick = () => navigate(route);

    return (
        <MenuItem selected={match?.isExact} data-cy={`${automation}-mobile`} onClick={onNavItemClick}>
            <Typography sx={{ textAlign: 'center' }}>{label}</Typography>
        </MenuItem>
    );
}
