export type ConstantColor = {
    name: string;
    color: string;
    textColor: string;
};

export const COLORS = {
    orange: {
        name: 'Orange',
        color: '#ff6a13',
        textColor: '#fff',
    } as ConstantColor,
    blue: {
        name: 'Blue',
        color: '#489fdf',
        textColor: '#fff',
    } as ConstantColor,
    cyan: {
        name: 'Cyan',
        color: '#38bfc4',
        textColor: '#fff',
    } as ConstantColor,
    teal: {
        name: 'Teal',
        color: '#007377',
        textColor: '#fff',
    } as ConstantColor,
    yellow: {
        name: 'Yellow',
        color: '#ebb923',
        textColor: '#fff',
    } as ConstantColor,
    red: {
        name: 'Red',
        color: '#cd051f',
        textColor: '#fff',
    } as ConstantColor,
    white: {
        name: 'White',
        color: '#fff',
        textColor: '#333',
    } as ConstantColor,
    black: {
        name: 'Black',
        color: '#333',
        textColor: '#fff',
    } as ConstantColor,
} as const;

export const BANNER_COLORS = {
    brown: {
        name: 'Brown',
        color: '#703600',
        textColor: '#fff',
    } as ConstantColor,
    red: {
        name: 'Red',
        color: '#6A0906',
        textColor: '#fff',
    } as ConstantColor,
    teal: {
        name: 'Teal',
        color: '#004D4A',
        textColor: '#fff',
    } as ConstantColor,
    blue: {
        name: 'Blue',
        color: '#1F386E',
        textColor: '#fff',
    } as ConstantColor,
    purple: {
        name: 'Purple',
        color: '#412D61',
        textColor: '#fff',
    } as ConstantColor,
    black: {
        name: 'Black',
        color: '#333333',
        textColor: '#fff',
    } as ConstantColor,
    white: {
        name: 'White',
        color: '#FFFFFF',
        textColor: '#333',
    } as ConstantColor,
} as const;

export const COUNTDOWN_COLORS = {
    orange: {
        name: 'Orange',
        color: '#F4633A',
        textColor: '#fff',
    } as ConstantColor,
    teal: {
        name: 'Teal',
        color: '#007377',
        textColor: '#fff',
    } as ConstantColor,
    pink: {
        name: 'Pink',
        color: '#FF6760',
        textColor: '#fff',
    } as ConstantColor,
    red: {
        name: 'Red',
        color: '#CB3B33',
        textColor: '#fff',
    } as ConstantColor,
    green: {
        name: 'Green',
        color: '#00A390',
        textColor: '#fff',
    } as ConstantColor,
    black: {
        name: 'Black',
        color: '#000000',
        textColor: '#fff',
    } as ConstantColor,
} as const;
