import {
    ARTICLE_TYPE_CUSHION,
    ARTICLE_TYPE_CUTOUT_PHOTOBOOK,
    ARTICLE_TYPE_JIGSAW,
    ARTICLE_TYPE_MAGNET,
    ARTICLE_TYPE_MOMENTS_PHOTOBOOK,
    ARTICLE_TYPE_PADDED_PHOTOBOOK,
    ARTICLE_TYPE_PHOTOBLOCK,
    ARTICLE_TYPE_STUDENTPLAKAT,
} from './articleType';

export interface Currency {
    symbol: string;
    abbreviation: string;
}

export interface Vendor {
    id: number;
    name: string;
    locale: string;
    country: string;
    currency: Currency;
    isB2B: boolean;
    isLive?: boolean;
}

// Arbitrary vendors order defined by marketing
export const VENDOR_ORDER = {
    NL: 1,
    BE: 2,
    NO: 3,
    SE: 4,
    UK: 5,
    DE: 6,
    FR: 7,
    AT: 8,
    PXXLDE: 9,
    'B2B-PXXLDE': 10,
    PBXUK: 11,
    PBXFR: 12,
    PBXIE: 13,
    PBXIT: 14,
    PBXDK: 15,
    HOFES: 16,
    HOFPT: 17,
};

export const VENDOR_UNSUPPORTED_ARTICLETYPES = {
    NO: [
        ARTICLE_TYPE_CUSHION,
        ARTICLE_TYPE_JIGSAW,
        ARTICLE_TYPE_MAGNET,
        ARTICLE_TYPE_PHOTOBLOCK,
        ARTICLE_TYPE_STUDENTPLAKAT,
        ARTICLE_TYPE_PADDED_PHOTOBOOK,
        ARTICLE_TYPE_CUTOUT_PHOTOBOOK,
        ARTICLE_TYPE_MOMENTS_PHOTOBOOK,
    ],
    SE: [ARTICLE_TYPE_PADDED_PHOTOBOOK],
    NL: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK],
    BE: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK],
    UK: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK, ARTICLE_TYPE_MOMENTS_PHOTOBOOK], //bonusprint needs deleted
    FR: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK, ARTICLE_TYPE_MOMENTS_PHOTOBOOK], //monalbumphoto needs deleted
    AT: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK], //PosterXXL AT
    PXXLDE: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK], //PosterXXL DE
    PBXUK: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK],
    PBXIE: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK],
    PBXFR: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK],
    PBXDK: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK],
    PBXIT: [ARTICLE_TYPE_STUDENTPLAKAT, ARTICLE_TYPE_PADDED_PHOTOBOOK],
    HOFES: [ARTICLE_TYPE_STUDENTPLAKAT],
    HOFPT: [ARTICLE_TYPE_STUDENTPLAKAT],
};

export const filterVendorSupportedArticleTypes = (articleTypes: string[], vendor: string): string[] => {
    return !!articleTypes
        ? articleTypes.filter(articleType => !VENDOR_UNSUPPORTED_ARTICLETYPES[vendor]?.includes(articleType))
        : [];
};
