import { MappedDefinitionOfferExtras } from 'components/campaigns/CampaignForm/CampaignDiscountCodeSelection/DiscountDefinitionParser';
import { parseAtomToDate, parseDateToAtom } from 'helpers/dateHelper';
import { Campaign } from 'models/campaign';
import { DiscountType } from 'models/discount';
import { Vendor, filterVendorSupportedArticleTypes } from './vendor';

export interface ArticleTypes {
    data: Array<ArticleType>;
}

export interface ArticleType {
    article_type: string;
    pap_id: string;
    extras: MappedDefinitionOfferExtras;
}
export interface Edited {
    edited?: boolean;
}

interface BaseOffer extends Edited {
    id?: number;
    type: DiscountType;
    campaign_id: number;
    vendor_id: number;
    promo_code: string;
    offer_value: number;
    offer_percentage: number;
    first_extension_date?: string;
    second_extension_date?: string;
    highest_offer: number;
    offer_label?: string | null;
}
export interface OfferApi extends BaseOffer {
    activation_date?: string;
    end_date?: string;
    article_types: ArticleTypes;
    vendor?: string;
}
export interface Offer extends BaseOffer {
    activation_date?: Date;
    end_date?: Date;
    article_types: string[];
    pap_ids: Array<string> | null;
    extras: MappedDefinitionOfferExtras[] | null;
    temporaryId?: string;

    cartRestriction: boolean;
}

export const isTemporaryOffer = (offer: Offer): boolean => {
    if (!offer.temporaryId || typeof offer.temporaryId === undefined) {
        return false;
    } else {
        return true;
    }
};

export const offerHasCampaignDates = (offer: Offer, campaign: Campaign): boolean => {
    return (
        offer.activation_date !== undefined &&
        offer.activation_date.getTime() === parseAtomToDate(campaign.activation_date).getTime() &&
        offer.end_date !== undefined &&
        offer.end_date.getTime() === parseAtomToDate(campaign.expiration_date).getTime()
    );
};

export const offerHasCustomDates = (offer: Offer, campaign?: Campaign): boolean => {
    return typeof offer.activation_date !== 'undefined' && typeof offer.end_date !== 'undefined';
};

export const mapOfferFromApi = (offerFromApi: OfferApi): Offer => {
    return {
        ...offerFromApi,
        activation_date: offerFromApi.activation_date ? parseAtomToDate(offerFromApi.activation_date) : undefined,
        end_date: offerFromApi.end_date ? parseAtomToDate(offerFromApi.end_date) : undefined,
        article_types: offerFromApi.article_types.data.map(art => art.article_type),
        pap_ids: offerFromApi.article_types.data.filter(art => !!art.pap_id).map(art => art.pap_id),
        extras: offerFromApi.article_types.data.filter(art => !!art.extras).map(art => art.extras),
        edited: false,
        cartRestriction: false, //TODO Check should we store this in the db
    };
};

export const mapOfferToApi = (offer: Offer): OfferApi => {
    const formattedArticleTypes: ArticleTypes = {
        data: offer.article_types.map(art => ({ article_type: art } as ArticleType)),
    };
    return {
        ...offer,
        activation_date: offer.activation_date ? parseDateToAtom(offer.activation_date) : undefined,
        end_date: offer.end_date ? parseDateToAtom(offer.end_date) : undefined,
        article_types: formattedArticleTypes,
    };
};

export const offerHasAllArticleTypes = (
    offer: Offer | OfferApi,
    articleTypes: Array<string>,
    vendor: Vendor,
): boolean => {
    const offerArticleTypes = Array.isArray(offer?.article_types) ? offer.article_types : offer?.article_types?.data;
    const vendorArticleTypes = filterVendorSupportedArticleTypes(articleTypes, vendor.country);
    if (offerArticleTypes) {
        return offerArticleTypes.length === vendorArticleTypes.length;
    }

    return false;
};

export const articleTypesToStringArray = (articleTypes: ArticleTypes): string[] =>
    articleTypes.data.map(articleType => articleType.article_type);

export const isOfferEqual = (offer1: Offer | OfferApi, offer2: Offer | OfferApi): boolean => {
    let equals = false;
    if (
        offer1.offer_percentage === offer2.offer_percentage &&
        offer1.offer_value === offer2.offer_value &&
        offer1.promo_code === offer2.promo_code &&
        offer1.vendor_id === offer2.vendor_id &&
        offer1.type === offer2.type &&
        offer1.article_types === offer2.article_types
    ) {
        equals = true;
    }

    return equals;
};

/**
 * Get to offer and compare are these the same
 * The offers should have the same Vendor, Type
 * Exact match for Article types and exact match
 * for PAP_IDs
 *
 * @param offer1
 * @param offer2
 * @returns boolean
 */
export const isOfferSame = (offer1: Offer, offer2: Offer): boolean => {
    const same =
        offer1.vendor_id === offer2.vendor_id &&
        offer1.type === offer2.type &&
        offer1.article_types.every(element => {
            return offer2.article_types.includes(element);
        }) &&
        offer2.article_types.every(element => {
            return offer1.article_types.includes(element);
        }) &&
        (offer1.pap_ids === offer2.pap_ids ||
            (!!offer1.pap_ids?.every(element => {
                return offer2.pap_ids?.includes(element);
            }) &&
                !!offer2.pap_ids?.every(element => {
                    return offer1.pap_ids?.includes(element);
                })));

    return same;
};

export const isSupersetOffer = (offer1: Offer, offer2: Offer): boolean => {
    return (
        offer1.vendor_id === offer2.vendor_id &&
        offer1.type === offer2.type &&
        JSON.stringify(offer1.article_types) !== JSON.stringify(offer2.article_types) &&
        offer1.article_types.every(element => {
            return offer2.article_types.includes(element);
        })
    );
};

export const isSubsetOffer = (offer1: Offer, offer2: Offer): boolean => {
    return (
        offer1.vendor_id === offer2.vendor_id &&
        offer1.type === offer2.type &&
        JSON.stringify(offer1.article_types) !== JSON.stringify(offer2.article_types) &&
        offer2.article_types.every(element => {
            return offer1.article_types.includes(element);
        })
    );
};
